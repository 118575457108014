
import React from "react";

import { NavLink } from "react-router-dom";

import { lotteryfilterd } from './../services/lottery';
import { get_books } from './../services/betting';
import graph from '../../assets/img/growing-graph.png'
import clock from '../../assets/img/clock.png'
import { checkresultchart } from './../services/chart';
import { compareTime } from "views/services/helper/helper";
import moment from 'moment';
import 'moment/locale/zh-cn';


// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col, Button, Modal, ModalHeader, ModalBody
} from "reactstrap";
import { i18n } from "components/i18n/hindi";
import { subtractTime } from "views/services/helper/helper";

// var hour = parseInt(moment().format('HH')) + 3;
// var min = parseInt(moment().format('mm'));
// var currentTime = moment().format('HH:mm');
// var extraTime = hour + ":" +min;
var now = new Date();

moment.locale('en');
var closetime = moment(now).add(15, "minutes").format('h:mm a');

class Lottery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isBlocked: false,
      bookstoggle: false,
      books: '',
      lotteryData: null,
      reftime: '',
      showPlay: 'true',
      showGameTime: false,
      GameDetail: null,
      GameEndTime: null,
      language: localStorage.getItem('language')
    };

    this.showBooks = this.showBooks.bind(this);
  };


  todayLottery = async () => {
    let lotteryData = await lotteryfilterd({ slug: "today", userId: localStorage.getItem("userId") });

    // data set before loop perform 
    this.setState({
      lotteryData: lotteryData.data.data,
      isBlocked: localStorage.getItem("is_blocked"),
      isLoaded: true,
    });

    for (let key = 0; key < lotteryData.data.data.length; key++) {
      let lottery = lotteryData.data.data[key]

      var hour = moment().hour();
      var from = null;
      var to = null;
      if (hour === 0 || hour === 1 || hour === 2 || hour === 3 || hour === 4 || hour === 5) {
        from = moment().subtract(1, 'd').format('YYYY-MM-DD');
        to = moment().format('YYYY-MM-DD');
        this.setState({ showPlay: false });
      } else {
        from = moment().format('YYYY-MM-DD');
        to = moment().add(1, 'd').format('YYYY-MM-DD');
        this.setState({ showPlay: true });
      }
      var checkrule = {
        from: from,
        to: to,
        lottery_id: lottery._id,
        bet_type: 'single-patti'
      };
      
      let lottryChartData = await checkresultchart(checkrule);
      if (lottryChartData.data.data) {
        
        lotteryData.data.data[key].result = lottryChartData.data.data;
      } else {
        lotteryData.data.data[key].result = '';
      }
      let betsData = await get_books({ user_id: localStorage.getItem("userId"), lottery_id: lottery._id });
      if (betsData.data.data) {
        lotteryData.data.data[key].bets = betsData.data.data;
      } else {
        lotteryData.data.data[key].bets = '';
      }


      if (lottery.result && lottery.result.open && lottery.result.patti && lottery.result.close) {
        lotteryData.data.data[key].placementIndex = 1
      } else if (lottery.result && lottery.result.open && lottery.result.patti) {
        lotteryData.data.data[key].placementIndex = 2
      } else if (lottery.result && lottery.result.open) {
        lotteryData.data.data[key].placementIndex = 4
      } else {
        lotteryData.data.data[key].placementIndex = 5
      }
    };

    this.setState({
      lotteryData: lotteryData.data.data,
      isBlocked: localStorage.getItem("is_blocked"),
      isLoaded: true,
    });
  }


  showBooks(books) {
    this.setState({
      bookstoggle: !this.state.bookstoggle,
      books: books
    });
  }

  showTimes(list) {
    {
      this.setState({
        showGameTime: true,
        GameDetail: list
      })
    }
  }
  hideTimes() {
    {
      this.setState({
        showGameTime: false,
        GameDetail: {},
      })
    }
  }

  componentDidMount() {
    this.todayLottery();
    this.interval = setInterval(() => {
      this.setState({ reftime: Date.now() })
      // this.todayLottery();
    }, 5000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { lotteryData, isLoaded } = this.state;
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="py-5 main-card">
                <CardBody>

                  <Row>
                    {isLoaded ? lotteryData.map((list, key) => {
                      return (
                        <>
                          {/* old code */}
                          {false &&
                            <Col sm={4} className="px-2" key={key}>
                              <Card className="border lottery-card text-center ">
                                <CardBody className="py-0">
                                  <div className="lottery-inner">
                                    <Row className="cardHeaderBottomBorder">
                                      <div className={"icon"}>
                                        <i className="nc-icon nc-planet" />
                                      </div>
                                      <span className={"live"}>
                                        {list.bets != 0 ? "LIVE" : ""}
                                      </span>
                                      <h5 className="col-7 mb-0 text-right px-1 date py-2 ml-auto">
                                        <Button onClick={() => this.showBooks(list.bets)} className="btn d-inline-flex btn-secondery m-0 mr-1 p-1" title="Books">
                                          <small>{this.state.language === "hin" ? i18n.LOTTERY.BOOKS.hindi : i18n.LOTTERY.BOOKS.english}</small>
                                        </Button>
                                        <NavLink className="btn btn-warning text-center text-dark d-inline-flex px-0 py-1 m-0 mr-2 w-50" to={"/dashboard/last-result/" + list._id}><small className="mx-auto">{this.state.language === "hin" ? i18n.LOTTERY.LAST_RESULT.hindi : i18n.LOTTERY.LAST_RESULT.english}</small></NavLink>
                                      </h5>
                                    </Row>
                                    <h4 className="my-2 uppercase">{list.market_name}</h4>
                                    <Row>
                                      <h6 className="col-5 mb-2 description uppercase">{this.state.language === "hin" ? i18n.LOTTERY.OPEN.hindi : i18n.LOTTERY.OPEN.english} {list.open}</h6>
                                      <h6 className="col-2 px-0 mb-2 description uppercase">{list.result && 'Result'}</h6>
                                      <h6 className="col-5 mb-2 description uppercase">{this.state.language === "hin" ? i18n.LOTTERY.CLOSE.hindi : i18n.LOTTERY.CLOSE.english} {list.close}</h6>
                                      {list && list.result && (list.result.hasOwnProperty('open') || list.result.hasOwnProperty('close')) ?
                                        <>
                                          <h6 className="col-5 py-2 mb-2 description uppercase theme-btn bg-yellow border text-center">{list.result && list.result.open ? list.result.open : null}</h6>
                                          <h6 className="col-2 py-2 px-0 mb-2 description uppercase theme-btn bg-yellow border text-center">{list.result && list.result.patti ? list.result.patti : null}</h6>
                                          <h6 className="col-5 py-2 mb-2 description uppercase theme-btn bg-yellow border text-center">{list.result && list.result.close ? list.result.close : null}</h6>
                                        </> : null
                                      }
                                    </Row>
                                  </div>
                                </CardBody>
                                {localStorage.getItem("is_blocked") === "false" ?
                                  <>
                                    {/* Don't remove static date b'coz Date function only work when date avialable. We only use time to check they passed or not*/}
                                    {this.state.showPlay && !moment(new Date("2021-07-16 " + list.close)).isBefore(new Date("2021-07-16 " + closetime)) ?
                                      <>
                                        {!list.result || !list.result.close ?
                                          <NavLink className="btn theme-btn btn-block mb-0" to={"/dashboard/gametype/" + list._id} color="danger">{this.state.language === "hin" ? i18n.LOTTERY.PLAY_NOW.hindi : i18n.LOTTERY.PLAY_NOW.english}</NavLink>
                                          : ''
                                        }
                                      </>
                                      : ''
                                    }
                                  </>
                                  : <>
                                    <NavLink className="btn theme-btn btn-block mb-0" to="#" color="danger">{this.state.language === "hin" ? i18n.LOTTERY.YOUR_ARE_BLOCKED.hindi : i18n.LOTTERY.YOUR_ARE_BLOCKED.english}</NavLink>
                                  </>}
                              </Card>
                            </Col>
                          }
                          {/* New Code */}
                          <Col sm={4} className="px-2" key={key}>
                            <Card className="border lottery-card bg-blue-icon text-center ">
                              <CardBody className="py-4">
                                <div className="lottery-inner">
                                  <Row className="">
                                    <div className={"lottery-icon"}>
                                      <img src={graph} alt={list.market_name} className="img-fluid" />
                                    </div>
                                    <span className={list && compareTime(list.close) ? "running" : "closed"}>
                                      {list && compareTime(list.close) ? "Betting is Running For Today" : "Betting is Closed for Today"}
                                    </span>
                                    {/* <h5 className="col-7 mb-0 text-right px-1 date py-2 ml-auto">
                                      <Button onClick={() => this.showBooks(list.bets)} className="btn d-inline-flex btn-secondery m-0 mr-1 p-1" title="Books">
                                        <small>{this.state.language === "hin" ? i18n.LOTTERY.BOOKS.hindi : i18n.LOTTERY.BOOKS.english}</small>
                                      </Button>
                                      <NavLink className="btn btn-warning text-center text-dark d-inline-flex px-0 py-1 m-0 mr-2 w-50" to={"/dashboard/last-result/" + list._id}><small className="mx-auto">{this.state.language === "hin" ? i18n.LOTTERY.LAST_RESULT.hindi : i18n.LOTTERY.LAST_RESULT.english}</small></NavLink>
                                    </h5> */}
                                  </Row>
                                  <h4 className="py-0 uppercase">{list.market_name}</h4>
                                  <Row>
                                    <Col>
                                      <button className="game-time-btn" onClick={() => this.showTimes(list)}>
                                        <img src={clock} />
                                        <span className="small">Game Time</span>
                                      </button>
                                    </Col>
                                    <Col>
                                      {localStorage.getItem("is_blocked") === "false" ?
                                        <>
                                          {/* Don't remove static date b'coz Date function only work when date avialable. We only use time to check they passed or not*/}
                                          {this.state.showPlay && !moment(new Date("2021-07-16 " + list.close)).isBefore(new Date("2021-07-16 " + closetime)) ?
                                            <>
                                              {list && compareTime(list.close) ?
                                                <NavLink className="btn p-0 m-0  bg-transparent mb-0 text-center" to={"/dashboard/gametype/" + list._id} color="danger">
                                                  <div id="play">
                                                    <div id="me"></div>
                                                  </div>
                                                  Play Now
                                                </NavLink>
                                                : ''
                                              }
                                            </>
                                            : ''
                                          }
                                        </>
                                        : <>
                                          <NavLink className="btn   bg-transparent mb-0" to="#" color="danger"><i className="fa fa-play-circle"></i><br /> {this.state.language === "hin" ? i18n.LOTTERY.YOUR_ARE_BLOCKED.hindi : i18n.LOTTERY.YOUR_ARE_BLOCKED.english}</NavLink>
                                        </>}

                                    </Col>

                                    {/* <h6 className="col-5 pb-2 px-1 description uppercase">{this.state.language === "hin" ? i18n.LOTTERY.OPEN.hindi : i18n.LOTTERY.OPEN.english} {list.open}</h6>
                                    <h6 className="col-2 px-0 pb-2 px-1 description uppercase">{list.result && 'Result'}</h6>
                                    <h6 className="col-5 pb-2 px-1 description uppercase">{this.state.language === "hin" ? i18n.LOTTERY.CLOSE.hindi : i18n.LOTTERY.CLOSE.english} {list.close}</h6> */}
                                    <>
                                      {/* Don't remove static date b'coz Date function only work when date avialable. We only use time to check they passed or not*/}
                                      <>
                                        {
                                          <Row className="btn theme-btn lottery-btn mb-0" color="danger">
                                            <>
                                              <h6 className="description uppercase text-center">{list.result && list.result.open ? list.result.open + "-" : "***-"}{list.result && list.result.patti ? list.result.patti + "-" : "**-"}{list.result && list.result.close ? list.result.close : "***"}</h6>
                                            </>
                                          </Row>

                                        }
                                      </>

                                    </>
                                  </Row>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </>
                      );
                    }) : ''}
                  </Row>
                </CardBody>
                <Modal size="lg" isOpen={this.state.showGameTime} fade={!this.state.showGameTime} toggle={() => this.hideTimes()}>
                  <ModalHeader toggle={() => this.hideTimes()}>{this.state.GameDetail?.market_name}</ModalHeader>
                  <ModalBody>
                    <Col className="table-responsive px-0 py-0 py-sm-0">
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <td><i className="fas fa-clock-o"></i> <span>Open Bid Ends</span></td>
                            <td><span className="timeR">{this.state.GameDetail && subtractTime(this.state.GameDetail?.open)}</span></td>
                          </tr>
                          <tr>
                            <td><i className="fas fa-clock-o"></i> <span>Close Bid Ends</span></td>
                            <td><span className="timeR">{this.state.GameDetail?.close && subtractTime(this.state.GameDetail?.close)}</span></td>
                          </tr>
                          <tr>
                            <td><i className="fas fa-clock-o"></i> <span>Open Result</span></td>
                            <td><span className="timeR">{this.state.GameDetail?.open}</span></td>
                          </tr>
                          <tr>
                            <td><i className="fas fa-clock-o"></i> <span>Close Result </span></td>
                            <td><span className="timeR">{this.state.GameDetail?.close}</span></td>
                          </tr>

                        </tbody>
                      </table>
                    </Col>
                  </ModalBody>
                </Modal>
                <Modal size="lg" isOpen={this.state.bookstoggle} fade={this.state.fade} toggle={() => this.showBooks('')}>
                  <ModalHeader toggle={() => this.showBooks('')}>Books</ModalHeader>
                  <ModalBody>
                    <Col className="table-responsive px-0 py-5 py-sm-0">
                      <table className="table table-striped">
                        <thead className="table-info text-primary">
                          <tr>
                            <th>Market Name</th>
                            <th>Result</th>
                            <th>Betting Time</th>
                            <th>Digit</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.books ? this.state.books.map((book, index) => (
                            <tr key={index}>
                              <td>{book.market_name}</td>
                              <td>{book.bet_type}</td>
                              <td>{book.betting_time}</td>
                              <td>{book.digit}</td>
                              <td>{book.bet_amount}</td>
                            </tr>
                          ))
                            :
                            <>
                              No Bets Here.
                            </>
                          }
                        </tbody>
                      </table>
                    </Col>
                  </ModalBody>
                </Modal>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Lottery;
